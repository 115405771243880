<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-check searchbar-content mr-5">
           <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
           <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault" :endDefault="endDefault" :configs="dateConfigs" />
         </div>
      </div>
      <div class="searchBar">
         <div class="box-ui-check searchbar-content">
           <div class="title"><span>{{ $t('searchArea.schDiv') }}</span></div>
           <div>
             <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="$t('searchArea.id')" />
             <button class="btn-search" type="button" @click="pageSeach">
                <i class="fas fa-search"></i>
             </button>
           </div>
         </div>
      </div>
      <memo></memo>
    </div>
    <div class="main-contents-wrapper">
      <article class="total-info-container fx-col-sbw">
        <div class="total-info-wrap">
          <div class="total-info-item">
            <img :src="require('@/assets/img/searchicon.png')" />
            <i class="fas fa-search"></i>
            <span>{{ $t('searchArea.schResult') }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_list_count`) }}</span> : <span class="value">{{ pageInfo.tatal_list_count }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.page`) }}</span> : <span class="value">{{ pageInfo.page }}</span>
          </div>
          <div class="total-info-item">
            <span class="title">{{ $t(`totalInfo.tatal_page_count`) }}</span> : <span class="value">{{ pageInfo.tatal_page_count }}</span>
          </div>
        </div>
        <div class="total-info-wrap">
          <!-- <button type="button" class="btn-layout btn-grey mr-5">테스트 메세지 추가</button> -->
          <!-- <button type="button" class="btn-layout btn-grey" @click="detailModalOpen('c')">추가</button> -->
        </div>
      </article>
      <div class="table-wrapper">
        <table class="mainTable">
          <table-head :headInfo="headInfo" />
          <tbody v-if="tableData.length > 0">
            <template v-for="item in tableData" :key="item.boardIdx">
              <tr>
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.regId)">{{ item.regId }}</button>
                </td>
                <td>
                  <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.regId)">{{ item.memName }}</button>
                </td>
                <td class="contentLimit tw-20" @click="showComment(item.comment)">
                  {{ item.title }}
                </td>
                <td class="contentLimit tw-40" @click="showComment(item.comment)">{{ item.content }}</td>
                <td class="roboto">{{ item.regDate }}</td>
                <td class="roboto">{{ numberWithCommas(item.viewCnt) }}</td>
                <td style="width: 10%">
                  <div class="status-change-btn-wrap">
                    <button class="btn-innerTable btn-status-change wait" type="button" @click="getContentDetail(item.boardIdx)">{{ $t('button.modify') }}</button>
                    <button class="btn-innerTable btn-status-change refuse" type="button" @click="deleteContent(item.boardIdx)">{{ $t('button.del') }}</button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="7">
              <span class="notice-tableDataEmpty">{{ $t('txt.noData') }}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="goSearch" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />

    <div class="modal-wrapper" v-if="commentModal">
      <div class="modal-wrap commentModal">
        <article class="modal-title">
          <sub-title :text="'댓글'" />
          <button class="btn-link modal-close" type="button" @click="commentModal=false">
            <i class="fas fa-times-circle"></i>
          </button>
        </article>
        <div class="table-wrapper">
          <table class="mainTable">
            <table-head :headInfo="commentHeadInfo" />
            <tbody>
              <template v-for="item in comment" :key="item.boardIdx">
                <tr>
                  <td>
                    <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.regId)">{{ item.regId }}</button>
                  </td>
                  <td>
                    <button type="button" class="fc-nick btn-link" @click="detailOpen('user', item.regId)">{{ item.memName }}</button>
                  </td>
                  <td class="contentLimit tw-40">{{ item.content }}</td>
                  <td>{{ item.regDate }}</td>
                  <!-- <td style="width: 10%">
                    <div class="status-change-btn-wrap">
                      <button class="btn-innerTable btn-status-change refuse" type="button" @click="deleteContent(item.boardIdx)">삭제</button>
                    </div>
                  </td> -->
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal-wrapper" v-if="updModalActive">
      <div class="modal-wrap">
        <article>
          <div class="sub-title">
            <h3>{{ $t('button.modify') }}</h3>
          </div>
          <div class="page-content">
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qTitle') }}</div>
                <div class="item-content">
                  <input type="text" v-model="boardDetail.title" />
                </div>
              </div>
            </div>
            <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">{{ $t('table.head.qContent') }}</div>
                <div class="item-content"><textarea v-model="boardDetail.content"></textarea></div>
              </div>
            </div>
            <!-- <div class="item-row-container">
              <div class="item-wrap">
                <div class="item-title" style="flex: 0 0 8.5%">우선 순위</div>
                <div class="item-content">
                  <input type="text" value="1" />
                </div>
              </div>
            </div> -->
            <div class="status-change-btn-wrap pt50">
              <button class="btn-innerTable btn-status-change approve" type="button" @click="updateBoard(boardDetail.boardIdx)">{{ $t('button.save') }}</button>
              <button class="btn-innerTable btn-status-change approve" type="button" @click="modalClose()">{{ $t('button.close') }}</button>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from "lodash";
import TableHead from "@/components/main/table/Head.vue";
import DateSelector from "@/components/common/DateSelector";
import Pagination from "@/components/common/Pagination";
import { getSiteData } from "@/libs/auth-helper";
import subTitle from "@/components/main/PageSubTitle";
import { boardList, boardDetail, boardSave } from "@/api/member.js";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
import Memo from '@/components/common/memo'

export default {
  name: "boardBoard",
  components: {
    TableHead,
    Pagination,
    DateSelector,
    subTitle,
    Memo
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: ["memId", "memNick", "qTitle2", "content", "writedate", "viewcount", "option"]
      },
      commentHeadInfo: {
        fstColumn: false,
        dataList: ["memId", "memNick", "content", "writedate"]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        boardType: "bbs",
        category: "",
        memId: "",
        startDate: "",
        endDate: ""
      },
      dateConfigs: {
        enableTime: false,
        dateFormat: "Y-m-d"
      },
      startDefault: "",
      endDefault: "",
      tableData: [],
      pageInfo: {},
      updModalActive: false,
      boardType: "bbs",
      boardDetail: {},
      comment: [],
      commentModal: false
    };
  },
  methods: {
    showComment(comment) {
      this.comment = comment;
      this.commentModal = true;
    },
    setStartDate(date) {
      // console.log(date);
      let _date = "";
      if (!date) {
        _date = new Date();
      } else {
        _date = date[0];
      }
      _date.setHours(0,0,0)
      this.startDefault = _date;
      this.reqData.startDate = getDateStr(_date, "yyyy-MM-dd");
    },
    setEndDate(date) {
      // console.log(date[0]);
      let _date = "";
      if (!date) {
        _date = new Date();
      } else {
        _date = date[0];
      }
      _date.setHours(23,59,59)
      this.endDefault = _date;
      this.reqData.endDate = getDateStr(_date, "yyyy-MM-dd");
    },
    goSearch(page) {
      this.setTableData(page);
    },
    pageSeach() {
      this.setTableData();
    },
    async deleteContent(boardIdx) {
      const trigger = confirm("해당 게시물을 삭제하시겠습니까?");
      if (!trigger) {
        return;
      }
      const req = {
        boardIdx: boardIdx,
        delYn: "Y"
      };
      const boardType = this.boardType;
      const res = await boardSave(boardType, req);
      console.log(res);
      if (res.resultCode === "0") {
        alert("게시물 삭제 완료");
        this.setTableData();
      } else {
        alert("게시물 삭제 실패, 다시 시도해주세요.");
      }
    },
    async updateBoard(boardIdx) {
      const trigger = confirm("해당 게시물을 수정하시겠습니까?");
      if (!trigger) {
        return;
      }
      const req = {
        boardIdx: boardIdx,
        title: this.boardDetail.title,
        content: this.boardDetail.content
      };
      const res = await boardSave(this.boardType, req);
      console.log(res);
      if (res.resultCode === "0") {
        alert("게시물 수정 완료");
        this.setTableData();
        this.modalClose();
      } else {
        alert("게시물 수정 실패, 다시 시도해주세요.");
      }
    },
    getSiteInfo() {
      const siteInfoCookie = getSiteData();
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie);
        return siteInfo;
      }
    },
    async createBoard() {
      const siteInfo = this.getSiteInfo();
      const trigger = confirm("해당 게시물을 등록 하시겠습니까?");
      if (!trigger) {
        return;
      }
      const req = {
        memId: siteInfo.siteId,
        title: this.boardDetail.title,
        content: this.boardDetail.content
      };
      const res = await boardSave(this.boardType, req);
      console.log(res);
      if (res.resultCode === "0") {
        alert("게시물 등록 완료");
        this.setTableData();
        this.modalClose();
      } else {
        alert("게시물 등록 실패, 다시 시도해주세요.");
      }
    },
    modalClose() {
      this.updModalActive = false;
      this.boardDetail = {};
    },
    detailModalOpen(type) {
      if (type === "c") {
        this.boardDetail = {};
      }
      this.updModalActive = true;
    },
    async getContentDetail(boardIdx) {
      const req = {
        boardIdx: boardIdx
      };
      const res = await boardDetail(this.boardType, req);
      this.boardDetail = res.data.board;
      console.log(this.boardDetail);
      this.detailModalOpen();
    },
    async setTableData(page) {
      if (page) {
        this.reqData.page = page;
      } else {
        this.reqData.page = 1;
      }
      const req = lodash.cloneDeep(this.reqData);
      const boardType = this.boardType;
      const res = await boardList(boardType, req);
      // console.log(res);
      if (res.data.pageInfo) {
        this.pageInfo = res.data.pageInfo;
        this.pageInfo.tatal_list_count = numberWithCommas(this.pageInfo.tatal_list_count);
        this.pageInfo.tatal_page_count = numberWithCommas(Number(this.pageInfo.tatal_page_count) + 1);
      }
      this.tableData = res.data.list;
    }
  },
  async created() {
    this.setStartDate();
    this.setEndDate();
    this.setTableData(1);
  }
};
</script>

<style scoped>
.mainTable{
  table-layout: fixed !important;
}
.sub-title {
  font-size: 1em;
  font-weight: 700;
  color: var(--main);
  height: 32px;
  position: relative;
  margin-bottom: 20px;
  border-bottom: 2px solid var(--main);
}
.sub-title > h3 {
  display: flex;
  height: 100%;
  align-items: center;
  width: 180px;
  font-size: 0.9em;
  border-start-end-radius: 10px;
  border-start-start-radius: 10px;
}
.sub-title > h3::before {
  content: "●";
  color: #18698b;
  margin-right: 7px;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.pt50 {
  padding-top: 50px;
}
tr:hover td {
  background: #e5e5e5;
}

thead th:nth-child(3) {
  width: 10%;
}
thead th:nth-child(4) {
  width: 50%;
}

.commentModal th:nth-child(1){
  width:10%;
}
.commentModal th:nth-child(2){
  width:10%;
}
.commentModal th:nth-child(3){
  width:60%;
}
.commentModal th:nth-child(4){
  width:20%;
}
tr {
  cursor: pointer;
}

.modal-wrapper {
  display: flex;
  font-size: 16px;
}
.contentLimit {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}
.commentModal {
  max-width: unset;
  min-width: 950px;
  width: 50%;
  padding-bottom: 15px;
}
.commentModal .table-wrapper {
  max-height: 400px;
  overflow-y: scroll;
}
.modal-close {
  font-size: 1.5em;
  position: absolute;
  top: -50%;
  right: 0;
  transform: translate(-50%, 100%);
}
</style>
